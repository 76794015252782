import {
  ComponentPropsWithRef,
  ReactNode,
  Ref,
  forwardRef,
  useId,
} from "react";

import { cn } from "@intergamma/common/cn";

import { UseComboboxProps, useCombobox } from "downshift";

import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";
import { inputStyles } from "./styles";

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type AutocompleteFieldProps<T> = ComponentPropsWithRef<"input"> & {
  className?: string;
  description?: ReactNode;
  error?: ReactNode;
  icon?: ReactNode;
  label: string;
  renderItem: (item: T) => ReactNode;
  options: UseComboboxProps<T> & {
    itemToKey: NonNullable<UseComboboxProps<T>["itemToKey"]>;
    itemToString: NonNullable<UseComboboxProps<T>["itemToString"]>;
  };
};

function AutocompleteFieldInner<T>(
  {
    className,
    description,
    error,
    label,
    options,
    renderItem,
    icon = null,
    ...inputProps
  }: AutocompleteFieldProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const id = useId();
  const {
    highlightedIndex,
    isOpen,
    getInputProps,
    getLabelProps,
    getItemProps,
    getMenuProps,
    selectedItem,
  } = useCombobox({ ...options, id });

  return (
    <div className={cn("flex flex-col", className)}>
      <Label {...getLabelProps()}>{label}</Label>
      <div className="relative">
        <input
          type="text"
          id={id}
          className={inputStyles({ error })}
          autoComplete="off"
          {...getInputProps({ ...inputProps, ref })}
        />
        {icon && <div className="absolute right-[14px] top-[14px]">{icon}</div>}

        <ul
          className={cn(
            "absolute top-full z-10 mt-1 max-h-72 w-full overflow-y-scroll border bg-white shadow-lg gamma:rounded-md karwei:rounded-none",
            "gamma:border-neutral-200 karwei:border-neutral-300",
            !(isOpen && options.items.length) && "hidden",
          )}
          {...getMenuProps()}
        >
          {isOpen
            ? options.items.map((item, index) => (
                <li
                  key={options.itemToKey(item)}
                  className={cn(
                    "cursor-pointer p-4",
                    "flex flex-1 px-4 py-3 text-100/7",
                    "hover:bg-ignew-neutral-25",
                    index === highlightedIndex && "bg-ignew-neutral-25",
                    item === selectedItem && "bg-ignew-neutral-25",
                  )}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(item)}
                </li>
              ))
            : null}
        </ul>
      </div>
      {error && <Error className="mt-1">{error}</Error>}
      {!error && description && (
        <Description className="mt-1">{description}</Description>
      )}
    </div>
  );
}

export const AutocompleteField = forwardRef(AutocompleteFieldInner);
